<template>
<div style="min-width:100%;min-height:100%;background:#E6E6E6">
   <div class="container">
      <div class="p-md-5 p-md-2 p-sm-1">
      
        
       <div class="row justify-content-center mt-5 p-3" >
           <div class="col-12 col-sm-10 col-md-6 col-lg-5 position-relative" style="box-shadow: 0px 0px 2px 2px grey;
    padding: 15px; border-radius: 15px;background: #fff;z-index: 1;
    overflow: hidden;">
               <img class="position-absolute" src="../assets/india/image/bookdemo.png" style="right: -96px;
    z-index: -1;
    top: -70px;
    width: 212px;" />
        <h1 class="text-center">
            <img  src="../assets/india/image/logo.png" class="px-4 py-2 img-fluid" style="border:1px solid black;border-radius:10rem;
            max-width: 180px;
    width: 55%;
    min-width: 100px;" />
        </h1>
        <h4 class="text-center fw-bold m-3" style="color:#FF9933;font-size: calc(0.5rem + 0.7vw);">
            <span style="border-bottom: 3px solid #E6E6E6;line-height: 35px;">Request A Callback</span>
        </h4>
               <Form class="mt-5"  @submit="onSubmit" :validation-schema="schema">
                    <div class="form-group mb-3">
                       <label class="text-muted">Name:</label>
                       <Field type="text" class="form-control" name="Name" placeholder="Name"/>
                           <ErrorMessage class="text-danger" name="Name" />
                   </div>
                   <div class="form-group mb-3">
                       <label class="text-muted">School Name:</label>
                       <Field type="text" class="form-control" name="SchoolName" placeholder="School Name"/>
                           <ErrorMessage class="text-danger" name="SchoolName" />
                   </div>
                   <div class="form-group mb-3">
                       <label class="text-muted">Mobile Number:</label>
                        <vue-tel-input style="height:45px;" @close="MobileNumberfocus"  @input ="MobileNumberChange" @focus="MobileNumberfocus"  mode="international" :autoDefaultCountry=false :defaultCountry="$store.state.country" :inputOptions="Mobileopt" validCharactersOnly=true>

                        </vue-tel-input>
                      
                           <p v-if="!ismobilevalid && ismobileFocus" class="text-danger" name="Mobile">Mobile number is not valid</p>
                   </div>
                   <div class="form-group mb-3">
                       <label class="text-muted">Email:</label>
                       <Field type="text" class="form-control" name="Email" placeholder="Email"/>
                           <ErrorMessage class="text-danger" name="Email" />
                   </div>
                   <div class="form-group mb-3">
 <vue-country-code
                  >
   </vue-country-code>
                   </div>
                   <div class="form-group mb-3">
                       <label class="text-muted">Are you a:</label>
                        <div class="radio-toolbar">
   <div style="display: inline;">
    <Field type="radio" id="Teacher" name="Type" value="Teacher"/>
    <label for="Teacher" style="margin: 0px;width:130px;margin-right: 10px">Teacher</label>
   </div>
    <div style="display: inline;">
    <Field type="radio" id="Principal" name="Type" value="Principal"/>
    <label for="Principal" style="margin: 0px;width:130px;margin-right: 10px">Principal</label>
   </div>
    <div style="display: inline;">
    <Field type="radio" id="Management" name="Type" value="Management"/>
    <label for="Management" style="margin: 0px;width:130px">Management</label>
   </div>

</div>
<ErrorMessage class="text-danger" name="Type" />
                   </div>
                  
                   <button type="submit" style="height:45px" class="btn  btn_orange_active">Submit</button>
                   <p class="mt-3" style="font-size: 0.8rem;">By registering here, I agree to Tinkerly <a target="_Blank" href="https://tinker.ly/terms-and-conditions/">Terms & Conditions</a> and <a target="_Blank" href="https://tinker.ly/privacy-policy-for-tinkerly/">Privacy Policy</a></p>
                   <div class="modal " v-if="sendOtp" style="display:block;background:rgba(0,0,0,0.8)">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
      <p  style="text-align: right;font-size: 2rem;color:#D1D1D1;">
          <a style="cursor: pointer;" @click="HideOtpDialog()" >&times;</a></p>
      <h4 class="text-center m-0">Enter the 6 digit OTP</h4>
      <p class="text-center" style="color:#D1D1D1">OTP sent to {{Mobile}}</p>
      <p class="text-center m-0"> 
          <input  class="partitioned text-center" v-model="otp1" v-mask="'#'"  v-on:keyup="gonext(0,$event)"   type="text" maxlength="1" />
          <input class="partitioned text-center" v-model="otp2" v-mask="'#'" v-on:keyup="gonext(1,$event)"  type="text" maxlength="1" />
          <input class="partitioned text-center" v-model="otp3" v-mask="'#'"  v-on:keyup="gonext(2,$event)"  type="text" maxlength="1" />
          <input class="partitioned text-center" v-model="otp4" v-mask="'#'" v-on:keyup="gonext(3,$event)"  type="text" maxlength="1" />
          <input class="partitioned text-center" v-model="otp5" v-mask="'#'"  v-on:keyup="gonext(4,$event)"  type="text" maxlength="1" />
          <input class="partitioned text-center" v-model="otp6" v-mask="'#'" v-on:keyup="gonext(5,$event)"  type="text" maxlength="1" />
          </p>
           <p v-if="otperror" class="text-danger text-center">{{otperror}}</p>
      <p class="text-center m-0">Not received OTP?</p>
     <p class="text-center m-0"><button  type="button" @click="resendOtp" class="btn btn-link">Resend</button></p>
     <p class="text-center"><button type="button" @click="submitOtp" style="height:45px" class="btn  btn_orange_active">Submit</button></p>
      </div>
     
    </div>
  </div>
</div>
               </Form >
           </div>
       </div>
      
       <div class="modal " v-if="isLoading" style="display:block;background:rgba(0,0,0,0.8)">
  <div class="modal-dialog modal-dialog-centered" style="height: 85px !important;width: 85px !important;" role="document">
    <div class="modal-content">
      <div class="modal-body">
     <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
      </div>
     
    </div>
  </div>
</div>
    </div>
   </div>
    </div>
</template>
<script>
    import '../assets/css/bootstrap.css'
    import '../assets/Font/stylesheet.css'
    import {mask} from 'vue-the-mask'
    import { Form, Field, ErrorMessage } from 'vee-validate';
   import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import * as yup from 'yup';
    export default {
        name: 'BookDemo',
         directives: {mask},
          components: {
    Form,
    Field,
    ErrorMessage,
    VueTelInput
  },
  data() {
    const schema = yup.object({
      Name: yup.string().required(),
      SchoolName: yup.string().required(),
      Type:yup.string().required(),
      Email:yup.string().email().required(),
    });

    return {
      schema,
      cnt:"IN",
      sendOtp:false,
      form:null,
      otp1:'',
      otp2:'',
      otp3:'',
      otp4:'',
      otp5:'',
      otp6:'',
      otperror:'',
      isLoading:false,
      otpenc :'',
      Mobile:'',
      ismobilevalid:true,
      ismobileFocus:false,
      Mobileopt:{
        id:"Mobile",
        name : "Mobile",
        maxlength:18,
        required:true,
        placeholder:"Mobile Number"
      }
    };
  },
  methods: {
    MobileNumberChange(num,data) {
      if(this.ismobileFocus){
      this.ismobilevalid = data.valid;
      if(data.valid === true)
      this.Mobile = "+"+data.countryCallingCode+"-"+data.nationalNumber;
      }
      console.log(data);
     },
     MobileNumberfocus(){
       console.log("focus");
       this.ismobileFocus = true;
       if(this.Mobile.length<5 && this.ismobilevalid)
       this.ismobilevalid = false;
     },
    onSubmit(values) {
      if(!this.ismobileFocus || !this.ismobilevalid || this.Mobile.length<8 )
      {
        this.ismobilevalid = false;
        this.ismobileFocus = true;
        return;
      }
      this.isLoading = true;
        var bodyFormData = new FormData();
        console.log(this.Mobile);
        bodyFormData.append('Name', values.Name);
        bodyFormData.append('Email', values.Email);
        bodyFormData.append('SchoolName', values.SchoolName);
        bodyFormData.append('Mobile', this.Mobile);
        bodyFormData.append('Type', values.Type);
        this.axios.post('https://alphalt.tinker.ly/api/V1/CallRequest',bodyFormData).
        then((response) => {
                 return response.data    
            })
            .then(() => {

      this.isLoading = false;
      this.$router.push({ name: 'School', params: { username: this.$route.params.username } });
  })
  .catch(error => {
     console.log(error);
    this.isLoading = false;
  });
     
    },
    gonext(e,event){
       var alls = document.getElementsByClassName("partitioned");
       if(event.target.value.trim().length == 0  && e==0){
           alls[0].focus();
           alls[0].select();
       }

       else if(event.target.value.trim().length == 0 && (event.key=="Backspace" || event.key=="Delete") && e>0)
        {
            alls[e-1].focus();
             alls[e-1].select();
        }
        else if(event.target.value.trim().length >0 && e<5){
            alls[e+1].focus();
             alls[e+1].select();
        }
    },
    
    resendOtp(){
        this.isLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append('Mobile', this.Mobile);
        this.axios.post('https://alphalt.tinker.ly/api/V1/SendOtpEnc',bodyFormData).
        then((response) => {
                 return response.data
                        
            })
            .then(data => { this.otpenc = data.data;
      this.sendOtp = true;
      this.isLoading = false;
  })
  .catch(error => {
     console.log(error);
    this.isLoading = false;
  });
    
     
    },
    submitOtp(){
        
        var otp = ""+this.otp1+this.otp2+this.otp3+this.otp4+this.otp5+this.otp6;
        if(otp.length!=6)
        this.otperror="Please enter a valid OTP";
        else{
            this.otperror="";
            this.isLoading = true;
             var bodyFormData = new FormData();
        bodyFormData.append('Mobile', this.Mobile);
        bodyFormData.append('Name', this.form.Name);
        bodyFormData.append('Auth', this.otpenc);
        bodyFormData.append('Otp', otp);
        bodyFormData.append('Grade', this.form.Grade);
        bodyFormData.append('Operation', 1);
        bodyFormData.append('CCode',this.cnt);
        bodyFormData.append('Refer',this.$route.params.username );
             this.axios.post('https://alphalt.tinker.ly/api/V1/LoginViaCode',bodyFormData).
             then((response) => {
                 return response.data   
            })
            .then(data => {
                //console.log(data.data);
                window.location.href = "https://alpha.letstinker.tinker.ly/bypass_login?id="+data.data;
                    this.sendOtp = true;
                    this.isLoading = false;
  })
  .catch(error => {
      this.isLoading = false;
      if(error.response.data.status.code==301)
      this.otperror="Invalid OTP";
  });
        }
    }
,
  HideOtpDialog(){
          this.sendOtp = false;
            this.otp1='';
            this.otp2='';
            this.otp3='';
            this.otp4='';
            this.otp5='';
            this.otp6='';
    }
  },
    beforeMount: function () {
       this.cnt = this.$store.state.country;
        
        },
        mounted: function () {
            window.__lc = window.__lc || {};
            window.__lc.license = 13188978;
            (function (n, t, c) {
                function i(n) { return e._h ? e._h.apply(null, n) : e._q.push(n) }
                var e = {
                    _q: [], _h: null, _v: "2.0", on: function () { i(["on", c.call(arguments)]) }, once: function () {
                        i(["once", c.call(arguments)])
                    }, off: function () { i(["off", c.call(arguments)]) },
                    get: function () {
                        if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
                        return i(["get", c.call(arguments)])
                    }, call: function () { i(["call", c.call(arguments)]) }, init: function () {
                        var n = t.createElement("script");
                        n.async = !0, n.type = "text/javascript",
                            n.src = "https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n)
                    }
                };
                !n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e
            }(window, document, [].slice))
        }
};
</script>
<style scoped>
.partitioned {
  padding: 10px 15px;
  border: 2px solid #D1D1D1;
  border-radius: 5px;
  margin: 10px;
  width: 45px;
}
input.partitioned:focus{
    outline: none;
    border: 2px solid #FF9933;
}
.btn-check:focus + .btn, .btn:focus{
    box-shadow: none;
}
.btn_orange_active {
        background: #FF9933;
        color:#fff;
        border:2px #FF6633 solid;
    }
    * {
        font-family: 'Mont' !important;
    }
    .form-control:focus{
        box-shadow: none;
    }
  .form-control{
      height: 45px;
      border: 2px solid #D1D1D1;
  }
    


.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
    display: inline-block;
    padding: 10px 0px;
    font-family: sans-serif, Arial;
    font-size: 16px;
    color: #000;
    border: 2px solid #D1D1D1;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    height: 45px;
    margin: 10px;
    width: 45px;
    text-align: center;
}

.radio-toolbar label:hover {
  background-color: #FF9933;
  border-color: #FF6633;
}



.radio-toolbar input[type="radio"]:checked + label {
   background-color: #FF9933;
  border-color: #FF6633;
}
</style>
